import { useEffect,useRef,useState  } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AiFillCamera, AiOutlineArrowLeft, AiOutlineHighlight, AiOutlineShopping, AiFillLeftCircle, AiFillRightCircle  } from 'react-icons/ai'
import { useSnapshot } from 'valtio'
import { state } from './store'
import { isBrowser, isMobile } from 'react-device-detect';

export function Overlay() {
  const snap = useSnapshot(state)
  const transition = { type: 'spring', duration: 0.8 }
  const config = {
    initial: { x: -100, opacity: 0, transition: { ...transition, delay: 0.5 } },
    animate: { x: 0, opacity: 1, transition: { ...transition, delay: 0 } },
    exit: { x: -100, opacity: 0, transition: { ...transition, delay: 0 } }
  }
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
      <motion.header initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} transition={transition}>
      </motion.header>
      <AnimatePresence>
        {snap.intro ? (
          <motion.section key="main" {...config}>
            <div className="section--container">
              <motion.div
                key="title"
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ type: 'spring', damping: 5, stiffness: 40, restDelta: 0.001, duration: 0.3 }}>
                <h1>MAPS</h1>
              </motion.div>
              <div className="support--content">
                <motion.div
                  key="p"
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    type: 'spring',
                    damping: 7,
                    stiffness: 30,
                    restDelta: 0.001,
                    duration: 0.6,
                    delay: 0.2,
                    delayChildren: 0.2
                  }}>
                  <p>
				  One-of-a-kind etched mirror maps of Cambodian cities offering a high-definition and contemporary perspective on Cambodian geography
                  </p>
                  <button style={{ background: snap.color }} onClick={() => (state.intro = false)}>
                    CUSTOMIZE IT <AiOutlineHighlight size="1.3em" />
                  </button>
                </motion.div>
              </div>
            </div>
          </motion.section>
        ) : (
          <motion.section key="custom" {...config}>
            <CustomizerHeader />
			<CustomizerSide />
			<Customizer />
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

function updateStyle(style) {
	if(style == 'custom') {
		// state.custom = true;
	} else {
		// state.custom = false;
		updateFrameColor(state.styles[style].color);
		updateBackground(state.styles[style].background_color);
		updateRoads(state.styles[style].road_color);
		updateWater(state.styles[style].water_color);
		if(state.styles[style].environment) {
			console.log(state.styles[style].environment);
			state.environment = state.styles[style].environment;
		}
	}
	if(state.road_styles[state.styles[style].road_color].night || state.water_styles[state.styles[style].water_color].night) {
		state.night = true;
	} else {
		state.night = false;
	}
	state.styles.custom.stylefile = state.styles[style].stylefile;
	state.style = style;
}
function updatePreset(preset) {
	updateFrameColor(state.presets[preset].color);
	updateBackground(state.presets[preset].background_color);
	updateRoads(state.presets[preset].road_color);
	updateWater(state.presets[preset].water_color);
	state.preset = preset;
	if(state.road_styles[state.presets[preset].road_color].night || state.water_styles[state.presets[preset].water_color].night) {
		state.night = true;
		console.log("Night Mode");
	} else {
		state.night = false;
		console.log("Day Mode", state.road_styles[state.presets[preset].road_color], state.water_styles[state.presets[preset].water_color]);
	}
}
function updateFrameColor(color) {
	// console.log(color);
	state.color = color;
}
function updateBackground(color) {
	// console.log(color);
	state.background_color = color;
}
function updateRoads(color) {
	// console.log(color);
	state.road_color = color;
}
function updateWater(color) {
	// console.log(color);
	state.water_color = color;
}

function updateCity(city) {
	console.log("Update size to "+city);
	if(state.mirrors[city]) {
		if(state.mirrors[city].sizes[state.orientation]) {
			if(state.mirrors[city].sizes[state.orientation]) {
				state.city = city;
				updateOrientation(state.orientation)
			}
		} else {
			const orientation = Object.keys(state.mirrors[city].sizes)[0];
			state.city = city;
			updateOrientation(orientation)
		}
	} else {
		console.log('City does not exist');
	}
	setTimeout(() => { 
		document.getElementById('sidebar_frame').scroll({
		  top: 80, 
		  left: 0, 
		  behavior: 'smooth' 
		}); 
	}, 500)
	
	// if(state.mirrors[state.city].sizes[state.orientation][size]) {
		// if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom]) {
			// console.log("Loading zoom "+state.zoom);
			// state.size = size;
		// } else {
			// console.log(state.mirrors[state.city].sizes[state.orientation][size]['default']);
			// console.log("Loading first zoom available "+state.mirrors[state.city].sizes[state.orientation][size]['default']);
			// state.zoom = state.mirrors[state.city].sizes[state.orientation][size]['default'];
			// state.size = size;
		// }
	// }
}
function updateOrientation(orientation) {
	console.log("Update orientation to "+orientation);
	const size = Object.keys(state.mirrors[state.city].sizes[orientation])[Object.keys(state.mirrors[state.city].sizes[orientation]).length - 1];
	console.log(Object.keys(state.mirrors[state.city].sizes[orientation]).length - 1);
	state.orientation = orientation;
	updateSize(size);
}

function updateOrientationAndSize(orientation, size) {
	state.orientation = orientation;
	updateSize(size);
}

function updateSize(size) {
	console.log("Update size to "+size);
	if(state.mirrors[state.city].sizes[state.orientation][size]) {
		if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom]) {
			console.log("Loading zoom "+state.zoom);
			console.log(state.mirrors[state.city].sizes[state.orientation][size][state.zoom]);
			if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom].indexOf(state.style) == -1) {
				console.log("Updating style to pro");
				state.style = "pro";
				updateStyle("pro");
			}
			state.size = size;
		} else {
			console.log("Loading first zoom available "+Object.keys(state.mirrors[state.city].sizes[state.orientation][size])[0]);
			state.zoom = Object.keys(state.mirrors[state.city].sizes[state.orientation][size])[0];
			console.log(state.style, state.mirrors[state.city].sizes[state.orientation][size][state.zoom].indexOf(state.style));
			if(state.mirrors[state.city].sizes[state.orientation][size][state.zoom].indexOf(state.style) == -1) {
				console.log("Updating style to pro");
				state.style = "pro";
				updateStyle("pro");
			}
			state.size = size;
		}
	}
}
function updateZoom(zoom) {
	// console.log(color);
	if(state.mirrors[state.city].sizes[state.orientation][state.size][zoom][state.style]) {
		state.zoom = zoom;
	} else {
		updateStyle("pro");
		state.zoom = zoom;
	}
}

function CustomizerHeader() {
  const snap = useSnapshot(state);
  return (
    <div className="customizer-header">
    </div>
  )
}
function goToCustomize() {
	console.log("goToCustomize");
  state.custom = true;
	setTimeout(() => { 
		document.getElementById('sidebar_frame').scroll({top: 0, behavior: 'smooth'})
	}, 500)
  
}

function CustomizerSide() {
  const snap = useSnapshot(state);
  const sidebar_frame = useRef()
  const slider = useRef()
  const slider_sizes = useRef()
  const [val, setVal] = useState(false); //initializes checkbox to false 
  const transition = { type: 'spring', duration: 0.8 } 
  const scrollToSizes = () => {
  const {current} = slider_sizes
	   if (current !== null){
		 current.scrollIntoView({behavior: "smooth"})
	   }
	   window.scrollTo({ top: 1000, behavior: "smooth" })
	}
  if(!state.total) state.total = {}
	if(!state.total[snap.city]) {
		{Object.keys(state.mirrors).map((city) => (
			state.total[city] = {
				sizes: 0,
				zoom: 0,
				styles: 0,
				total : 0
			},
			Object.keys(state.mirrors[city].sizes).map((orientation) => (
				Object.keys(state.mirrors[city].sizes[orientation]).map((size) => (
					state.total[city].sizes++
				))
			))
		))}
	}
  // console.log(.indexOf(style));
  // <img style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/10+"cm", width: state.frame_sizes[orientation][size].width/10+"cm"}} className="frame_border" src={"/"+orientation+"_empty.svg"} />

  useEffect( () => {
  if ( slider.current ) {
	slider.current.go(Object.keys(snap.mirrors).indexOf(snap.city));
  }
  if ( slider_sizes.current ) {
	slider_sizes.current.go(Object.keys(snap.mirrors[snap.city].sizes[snap.orientation]).indexOf(snap.size) + 1);
  }
  
} );
  return (
    <div  className="customizer-side" >
		<div className="frame" id="sidebar_frame" ref={sidebar_frame}>
			{snap.custom ? (
			<motion.div key="step2" initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} transition={transition}>
			  
				<div className="fbutton_container center">
					{Object.keys(snap.styles).map((style) => (
					  style !== "custom" && (
						state.mirrors[snap.city].sizes[snap.orientation][snap.size][snap.zoom].indexOf(style) > -1 ? ( 
							<div title={style} key={style} style={{ background: state.background_styles[state.styles[style].background_color].color, color: state.road_styles[state.styles[style].road_color].color }} className={snap.style == style ? `city_selector_item active` : `city_selector_item big`} onClick={() => (updateStyle(style))}>
								{state.styles[style].name}
							</div> 
						) : null ) : null
					))}
				  </div>
				<div className="fbutton_container center">
					{Object.keys(snap.presets).map((style) => (
						<div title={style} key={style} style={{ background: state.background_styles[state.presets[style].background_color].color, color: state.road_styles[state.presets[style].road_color].color }} className={snap.preset == style ? `city_selector_item active` : `city_selector_item big`} onClick={() => (updatePreset(style))}>
							{state.presets[style].name}
						</div> 
					))}
				  </div>
				  <div className="color-options" style={{display: state.custom ? 'flex' : 'none'}}> Frame
					{snap.colors.map((color) => (
					  <div key={color} className={snap.color == color ? `circle active` : `circle`} style={{ background: color }} onClick={() => (updateFrameColor(color))}></div>
					))}
				  </div>
				  <div className="color-options" style={{display: state.custom ? 'flex' : 'none'}} > Background:
					{Object.keys(snap.background_styles).map((color) => (
					  <div title={color} key={color} className={snap.background_color == color ? `circle active` : `circle`} style={{ background: snap.background_styles[color].color }} onClick={() => (updateBackground(color))}></div>
					))}
				  </div>
				  <div className="color-options" style={{display: state.custom ? 'flex' : 'none'}}> Roads:
					{Object.keys(snap.road_styles).map((color) => (
					  <div title={color} key={color} className={snap.road_color == color ? `circle active` : `circle`} style={{ background: snap.road_styles[color].color }} onClick={() => (updateRoads(color))}></div>
					))}
				  </div>
				  <div className="color-options" style={{display: state.custom ? 'flex' : 'none'}}> Water:
					{Object.keys(snap.water_styles).map((color) => (
					  <div title={color} key={color} className={snap.water_color == color ? `circle active` : `circle`} style={{ background: snap.water_styles[color].color }} onClick={() => (updateWater(color))}></div>
					))}
				</div>
			</motion.div>
			) : (
			<motion.div key="step1" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} transition={transition}>
				<Splide tag="div" onReady={( splide ) => { setTimeout(() => { splide.emit('resized') }, 500) }} aria-label="Cities" ref={slider}  options={ {width : "100%", height : '64px', padding: '4px', gap : '4px', 'autoWidth': true, focus: 'center',drag   : 'free',} }>
					{Object.keys(snap.mirrors).map((city, key) => (
						  <SplideSlide key={key} className={snap.city == city ? "city_selector_item active" : "city_selector_item"} onClick={() => (scrollToSizes, updateCity(city))}>
							<img alt={snap.mirrors[city].name} src={'/maps/'+city+'/font.svg'} />
								{state.total[city].sizes > 1 ? <span className="badge">{state.total[city].sizes}</span> : null}
						  </SplideSlide>
					))}
				</Splide>
				<Splide tag="div"  className="size_selector" aria-label="Cities" ref={slider_sizes}  options={ { width : "100%", padding: '4px', gap : '4px', 'autoWidth': true, focus: 'center',drag   : 'free',} }>
					{Object.keys(snap.mirrors[snap.city].sizes).map((orientation) => (
						Object.keys(snap.mirrors[snap.city].sizes[orientation]).map((size) => (
						  <SplideSlide key={size} className="size_item_container" onClick={() => (updateOrientationAndSize(orientation, size))}>
							<div style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/10+"cm", width: state.frame_sizes[orientation][size].width/10+"cm"}} className={snap.size == size ? "frame_template size_item active" : "frame_template size_item"} >
							
								{ isBrowser ? <img style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/10+"cm", width: state.frame_sizes[orientation][size].width/10+"cm"}} className="water" src={"/maps/"+snap.city+"/"+orientation+"/"+size+"/"+Object.keys(snap.mirrors[snap.city].sizes[orientation][size])[0]+"/pro/water.png"} /> : null}
								{ isBrowser ? <img style={{ aspectRatio: ""+state.frame_sizes[orientation][size].width+"/"+state.frame_sizes[orientation][size].height+"", height: state.frame_sizes[orientation][size].height/10+"cm", width: state.frame_sizes[orientation][size].width/10+"cm"}} className="roads" src={"/maps/"+snap.city+"/"+orientation+"/"+size+"/"+Object.keys(snap.mirrors[snap.city].sizes[orientation][size])[0]+"/pro/roads.png"} /> : null}
								<span className="frame_size">{state.frame_sizes[orientation][size].width}x{state.frame_sizes[orientation][size].height}</span>
								{Object.keys(snap.mirrors[snap.city].sizes[orientation][size]).length > 1 ? <span className="badge static">{Object.keys(snap.mirrors[snap.city].sizes[orientation][size]).length}</span> : null}
								
							</div>
						  </SplideSlide>
						))
					))}
				</Splide>
				{Object.keys(snap.mirrors[snap.city].sizes[snap.orientation][snap.size]).length > 1 ? 
				<div className="fbutton_container center">
					{Object.keys(snap.mirrors[snap.city].sizes[snap.orientation][snap.size]).map((zoom) => (
						<div key={zoom} className={snap.zoom == zoom ? "fbutton scale active " : "fbutton scale"} onClick={() => (updateZoom(zoom))}><span className="zoom_numeric">1:{zoom}</span><br /><span className="zoom_cm">1CM = {zoom / 100}M</span></div>
					))}
				</div> : null}
			</motion.div>
			)}
			<div className="sidebar_bottom">
			  <button className="back_btn"  style={{ background: snap.color }} onClick={() => (state.custom ? state.custom = false : state.intro = true)}>
				<AiFillLeftCircle size="1.3em" /> BACK
				{state.custom ? <span className="legend">Select city and size</span> : null}
			  </button>
				<div className="fbutton_container price">
					<span>From {state.frame_sizes[snap.orientation][snap.size].price}$</span>
					<span><small>{state.frame_sizes[snap.orientation][snap.size].price * 4000} KHR</small></span>
				</div>
				  <button className="next_btn" style={{ background: snap.color }} onClick={() => (goToCustomize())}>
						<AiFillRightCircle size="1.3em"  /> 
						<span>Continue</span>
						<span className="legend">Select frame and colors</span>
				  </button>
			</div>
		</div>
    </div>
  )
}
function Customizer() {
  const snap = useSnapshot(state);
  return (
    <div className="customizer">
      
      <button
        className="share"
        style={{ background: snap.color, display: 'none' }}
        onClick={() => {
			
			state.showCanvaText = true
          const link = document.createElement('a')
          link.setAttribute('download', 'canvas.png')
          link.setAttribute('href', document.querySelector('canvas').toDataURL('image/png').replace('image/png', 'image/octet-stream'))
          link.click()
        }}>
        DOWNLOAD
        <AiFillCamera size="1.3em" />
      </button>
    </div>
  )
}
