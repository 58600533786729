import * as THREE from 'three'
import { useRef, useEffect, Suspense } from 'react'
import { Canvas, useFrame, useThree  } from '@react-three/fiber'
import { Loader, useGLTF, useTexture, AccumulativeShadows, RandomizedLight, Decal, Environment, Center, Text, Text3D, MeshReflectorMaterial, OrbitControls, useMatcapTexture, useProgress, Html, Lightformer, Float  } from '@react-three/drei'
import { easing } from 'maath'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { useSnapshot } from 'valtio'
import { state } from './store'
import { isBrowser, isMobile } from 'react-device-detect';
import { LayerMaterial, Color, Depth } from 'lamina'

export const App = ({ position = [0, 0, 2.5], fov = 22 }) => (
	<div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
		<Suspense fallback={null}>
			<Canvas shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client" onCreated={(state) => {preloadMaps() }}>
					<AmbientLight />
					<Env />
					<OrderText />
					<CameraRig>
						<Backdrop />
						<Center>

							<MeshFrame />
						</Center>
					</CameraRig>
					<Flower />
			</Canvas>
		</Suspense>
		<Loader />
	</div>
)


function AmbientLight() {
  const snap = useSnapshot(state)
  return (
   <ambientLight intensity={snap.night ? 0 : 1} />
  )
}
function Env() {
  const snap = useSnapshot(state)
  return (
    <Environment preset={snap.environment} environmentIntensity={snap.night ? 0.2 : 1} backgroundBlurriness={0.5} > 
    </Environment>
  )
}
function OrderText() {
	 const [matcapTexture] = useMatcapTexture("9D9D9D_4E4E4E_646464_6C6C6C");
  const snap = useSnapshot(state)
  const group = useRef()
  const ref = useRef()
  const text_height = useRef()
  const text_width = useRef()
  const infotext_ref = useRef()
  
  const top_text = ((state.frame_sizes[snap.orientation][snap.size].height + 14) / 2 )/ 100;
  const mirror_text = ((state.frame_sizes[snap.orientation][snap.size].height + 4) / 2 )/ 100;
  const mirror_text_left = ((state.frame_sizes[snap.orientation][snap.size].height) )/ 100;
  const bottom_text = -((state.frame_sizes[snap.orientation][snap.size].height + 8) / 2 )/ 100;
  const right_text = ((state.frame_sizes[snap.orientation][snap.size].width + 6) / 2 )/ 100;
  const left_text = -((state.frame_sizes[snap.orientation][snap.size].width + 14) / 2 )/ 100;
  const info_text_right = ((state.frame_sizes[snap.orientation][snap.size].width + 12) / 2 )/ 100;
  const info_text_bottom = -((state.frame_sizes[snap.orientation][snap.size].height - 2) / 2 )/ 100;
  const info_custom = snap.style == 'custom' ? "("+state.styles.custom.stylefile+")" : "";
  
  const infotext = " Style "+snap.style+" "+info_custom+" \n 1:"+snap.zoom+"\n "+snap.road_color+" roads\n "+snap.water_color+" water\n "+snap.background_color+" background";
  // console.log(top_text);
  
				// <Text3D
					// fillOpacity={!snap.intro}
				  // position={[0, mirror_text, -1.45]}
				  // scale={[0.1, 0.1, 0.1]}
				  // ref={ref}
				  // size={0.5}
				  // font={"/gt.json"}
				  // maxWidth={[3, 1]}
				  // curveSegments={24}
				  // brevelSegments={1}
				  // bevelEnabled
				  // bevelSize={0.03}
				  // bevelThickness={0.03}
				  // height={0.5}
				  // lineHeight={0.9}
				  // letterSpacing={0.05}
				// >
				  // {snap.mirrors[snap.city].name}
				  // <meshMatcapMaterial color="white" matcap={matcapTexture}/>
				// </Text3D>
	
		  // <Text position={[0, top_text, 0]} fillOpacity={snap.intro} color="black" fontSize={0.06} font="Inter-Regular.woff" letterSpacing={-0.05}>
			  // {snap.mirrors[snap.city].name}
		  // </Text>
  useFrame((state, delta) => {
    easing.damp3(text_height.current.position, [right_text, 0, 0], 0.1, delta)
    easing.damp3(text_width.current.position, [0, bottom_text, 0], 0.1, delta)
    easing.damp3(infotext_ref.current.position, [info_text_right, info_text_bottom, 0], 0.1, delta)
  })
  return (
	<group ref={group} >
		  <Text ref={text_height} rotation={[0, 0, -Math.PI / 2]} fillOpacity={!snap.intro} position={[0, 0, 0]} color="white" fontSize={0.03} letterSpacing={-0.05}>
			 {state.frame_sizes[snap.orientation][snap.size].height} CM
		  </Text>
		  <Text ref={text_width} position={[0, 0, 0]} fillOpacity={!snap.intro} color="white" fontSize={0.03}  letterSpacing={-0.05}>
			 {state.frame_sizes[snap.orientation][snap.size].width} CM
		  </Text>
		  <Text ref={infotext_ref} position={[0, 0, 0]} fillOpacity={!snap.intro} color="white" fontSize={0.01} letterSpacing={-0.05}>
			  {infotext}
		  </Text>
	</group>
  )
}

	  
function Backdrop() {
  const shadows = useRef()
  const snap = useSnapshot(state)
  useFrame((state, delta) => {
	  easing.dampC(shadows.current.getMesh().material.color, state.color, 0.25, delta)
  })
  return (
    <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.85} scale={10} rotation={[Math.PI / 2, 0, 0]} position={[0, 0, -0.14]}>
      <RandomizedLight amount={4} radius={9} intensity={snap.night ? 0.5 : 1.25} ambient={0.55} position={[5, 5, -10]} />
      <RandomizedLight amount={4} radius={5} intensity={snap.night ? 0.5 : 1.85} ambient={0.55} position={[-5, 5, -9]} />
    </AccumulativeShadows>
  )
}

function CameraRig({ children, v = new THREE.Vector3() }) {
  const group = useRef()
  const snap = useSnapshot(state)
  useFrame((state, delta) => {
    const t = state.clock.elapsedTime
	
    isBrowser && state.size.width > 1279 ? state.camera.lookAt(snap.intro ? -state.size.width / 8000 : -((state.size.width / 4000) ) / 4  + ((snap.frame_sizes[snap.orientation][snap.size].zoom / 100) * 2), 0, 0) : null;
	
    isBrowser && state.size.width > 1279 ? easing.damp3(state.camera.position, [snap.intro ? -state.size.width / 8000 * Math.sin(t / 8) : -((state.size.width / 4000) * Math.sin(t / 10) ) / 4  + ((snap.frame_sizes[snap.orientation][snap.size].zoom / 100) * 2), Math.cos(t / 10) / 4, !snap.intro ? snap.frame_sizes[snap.orientation][snap.size].zoom : 2], 0.1, delta) : null
    
	isBrowser && state.size.width < 1280 ? easing.damp3(state.camera.position, [0, snap.intro ? 0 : -0.22, !snap.intro ? snap.frame_sizes[snap.orientation][snap.size].zoom * 1.6 : 2], 0.1, delta) : null
    isMobile ?                             easing.damp3(state.camera.position, [0, snap.intro ? 0 : -0.22 + ((2.3 - snap.frame_sizes[snap.orientation][snap.size].zoom) / 10), !snap.intro ? snap.frame_sizes[snap.orientation][snap.size].zoom * 1.4 : 3], 0.1, delta) : null
    isBrowser ? easing.dampE(group.current.rotation, [state.pointer.y / 10, -state.pointer.x / 5, 0], 0.25, delta) : easing.dampE(group.current.rotation, [state.pointer.y / 10, -state.pointer.x / 5, 0], 0.25, delta)
  })
  return <group ref={group}>{children}</group>
}
function Flower(props) {
  const snap = useSnapshot(state)
  const { nodes, materials } = useGLTF('/flower.glb')
  // console.log(nodes);
  return (
    <group {...props}>
		<mesh castShadow scale={[0.01, 0.01, 0.01]} position={[0.7, 0, -0]} geometry={nodes['Leaves_Mat1_0'].geometry} material={materials['Mat.1']} material-roughness={1} {...props} dispose={null}>
		</mesh>
		<mesh castShadow scale={[0.01, 0.01, 0.01]} position={[0.7, -0.54, -0]} geometry={nodes['root_HP_1_Mat2_0'].geometry} material={materials['Mat.2']} material-roughness={1} {...props} dispose={null}>
		</mesh>
	</group>
  )
}

function MeshFrame(props) {
	const group = useRef()
	const snap = useSnapshot(state)
	const frame = useRef()
	const mirror = useRef()
	useTexture.preload(`/maps/`+snap.city+`/`+snap.orientation+`/`+snap.size+`/`+snap.zoom+`/`+state.styles[snap.style].stylefile+`/roads.png`)
	useTexture.preload(`/maps/`+snap.city+`/`+snap.orientation+`/`+snap.size+`/`+snap.zoom+`/`+state.styles[snap.style].stylefile+`/water.png`)
	const texture_black = useTexture(`/maps/`+snap.city+`/`+snap.orientation+`/`+snap.size+`/`+snap.zoom+`/`+state.styles[snap.style].stylefile+`/roads.png`)
	const texture_water = useTexture(`/maps/`+snap.city+`/`+snap.orientation+`/`+snap.size+`/`+snap.zoom+`/`+state.styles[snap.style].stylefile+`/water.png`)
  
	const width = (state.frame_sizes[snap.orientation][snap.size].width + 3) / 100;
	const height = (state.frame_sizes[snap.orientation][snap.size].height + 3) / 100;
	
	const strokew = (state.frame_sizes[snap.orientation][snap.size].width * 100 / (state.frame_sizes[snap.orientation][snap.size].width + 3)) / 100;
	const strokeh = (state.frame_sizes[snap.orientation][snap.size].height * 100 / (state.frame_sizes[snap.orientation][snap.size].height + 3)) / 100;
	
	useFrame((state, delta) => {
		easing.dampC(frame.current.material.color, snap.color, 0.25, delta)
		easing.dampE(frame.current.scale, [width, height, 0.015], 0.1, delta)
		easing.dampE(mirror.current.scale, [strokew, strokeh, 0.9], 0.1, delta)
	})
  return (
    <group {...props}>
		<mesh
			ref={frame}
			castShadow
			scale={[0.24, 0.30, 0.015]}
			position={[0, 0.05, 0]}>
			<boxGeometry />
			<meshStandardMaterial metalness={0} roughness={1} />
			<mesh ref={mirror} castShadow scale={[0.94, 0.94, 0.9]} position={[0, 0, 0.2]} {...props} dispose={null}>
				<boxGeometry />
				<EffectComposer smaa>
					<Bloom luminanceThreshold={0.2} mipmapBlur luminanceSmoothing={0} intensity={snap.night ? 1 : 0.2} />
				</EffectComposer>
					<Decal mesh={group} position={[0, 0, 0.1]} rotation={[0, 0, 0]}>
						<meshStandardMaterial
						transparent
						  // depthTest={false}
						  color={snap.background_styles[snap.background_color].color}
						  toneMapped={false}
						  roughness={snap.background_styles[snap.background_color].roughness}
						  emissive={'#FFFFFF'}
						  emissiveIntensity={snap.background_styles[snap.background_color].emissiveIntensity}
						  metalness={snap.background_styles[snap.background_color].metalness}
						  onUpdate={(self) => (self.needsUpdate = true)}
						/>
					</Decal>
					<Decal mesh={group} position={[0, 0, 0.1]} rotation={[0, 0, 0]}>
						<meshStandardMaterial
						  transparent
						  alphaMap={texture_water}
						  emissiveMap={texture_water}
						  emissive={snap.water_styles[snap.water_color].emissive}
						  emissiveIntensity={snap.road_styles[snap.road_color].emissiveIntensity}
						  // depthTest={false}
						  color={snap.water_styles[snap.water_color].color}
						  toneMapped={false}
						  roughness={snap.water_styles[snap.water_color].roughness}
						  metalness={snap.water_styles[snap.water_color].metalness}
						  onUpdate={(self) => (self.needsUpdate = true)}
						/>
					</Decal>
					<Decal mesh={group} position={[0, 0, 0.1]} rotation={[0, 0, 0]}>
						<meshStandardMaterial
						  transparent
						  attach="material"
						  alphaMap={texture_black}
						  emissiveMap={texture_black}
						  emissive={snap.road_styles[snap.road_color].emissive}
						  emissiveIntensity={snap.road_styles[snap.road_color].emissiveIntensity}
						  // depthTest={false}
						  color={snap.road_styles[snap.road_color].color}
						  toneMapped={false}
						  roughness={snap.road_styles[snap.road_color].roughness}
						  metalness={snap.road_styles[snap.road_color].metalness}
						  onUpdate={(self) => (self.needsUpdate = true)}
						/>
					</Decal>
			</mesh>
		</mesh>
    </group>
  )
}

function Lightformers({ positions = [2, 0, 2, 0, 2, 0, 2, 0] }) {
  const group = useRef()
  useFrame((state, delta) => (group.current.position.z += delta * 10) > 20 && (group.current.position.z = -60))
  return (
    <>
      {/* Ceiling */}
      <Lightformer intensity={0.75} rotation-x={Math.PI / 2} position={[0, 5, -9]} scale={[10, 10, 1]} />
      <group rotation={[0, 0.5, 0]}>
        <group ref={group}>
          {positions.map((x, i) => (
            <Lightformer key={i} form="circle" intensity={2} rotation={[Math.PI / 2, 0, 0]} position={[x, 4, i * 4]} scale={[3, 1, 1]} />
          ))}
        </group>
      </group>
      {/* Sides */}
      <Lightformer intensity={4} rotation-y={Math.PI / 2} position={[-5, 1, -1]} scale={[20, 0.1, 1]} />
      <Lightformer rotation-y={Math.PI / 2} position={[-5, -1, -1]} scale={[20, 0.5, 1]} />
      <Lightformer rotation-y={-Math.PI / 2} position={[10, 1, 0]} scale={[20, 1, 1]} />
      {/* Accent (red) */}
      <Float speed={5} floatIntensity={2} rotationIntensity={2}>
        <Lightformer form="ring" color="red" intensity={1} scale={10} position={[-15, 4, -18]} target={[0, 0, 0]} />
      </Float>
      {/* Background */}
      <mesh scale={100}>
        <sphereGeometry args={[1, 64, 64]} />
        <LayerMaterial side={THREE.BackSide}>
          <Color color="#444" alpha={1} mode="normal" />
          <Depth colorA="blue" colorB="black" alpha={0.5} mode="normal" near={0} far={300} origin={[100, 100, 100]} />
        </LayerMaterial>
      </mesh>
    </>
  )
}

useGLTF.preload('/flower.glb')

function preloadMaps() {
	var preload = [];
	{Object.keys(state.mirrors).map((city) => (
		Object.keys(state.mirrors[city].sizes).map((orientation) => (
			Object.keys(state.mirrors[city].sizes[orientation]).map((size) => (
				Object.keys(state.mirrors[city].sizes[orientation][size]).map((zoom) => (
					state.mirrors[city].sizes[orientation][size][zoom]).map((style) => (
						preload.push(`/maps/`+city+`/`+orientation+`/`+size+`/`+zoom+`/`+state.styles[style].stylefile+`/water.png`, `/maps/`+city+`/`+orientation+`/`+size+`/`+zoom+`/`+state.styles[style].stylefile+`/roads.png`)
					)
				))
			))
		))
	))}
	setTimeout(() => { preload.forEach(useTexture.preload) }, 2000)
}